export default {
  'Enable webhook?': 'Enable webhook?',
  'Enable': 'Enable',
  'enablingWebhookStartEvents':
    'By enabling this webhook endpoint it will start receiving notifications about events.',

  'Disable webhook?': 'Disable webhook?',
  'Disable': 'Disable',
  'disablingWebhookStopEvents':
    'By temporary disabling this webhook endpoint it will stop receiving notifications about events.',

  'Remove webhook?': 'Remove webhook?',
  'removingWebhookNoEventSent':
    'Removing this webhook means that no webhook messages will be sent whenever this event occurs within your organization.',
}
