<script>
import './assets/scss/main.scss' // should be included here to bundle in correct order i.e. before modals and toast
import { computed, nextTick, watch } from 'vue'
import { fallbackLang } from '@keyo/core/i18n'

import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { whenever } from '@vueuse/core'

import DashboardLayout from '@/layouts/DashboardLayout'

import Toast from './libs/toast/Toast.vue'
import Modal from './components/modals/Modal.vue'

import { useDevicesStore, usePersonalStore } from '@/store'
import { useMembersStore } from '@/modules/members/pinia'
import { chatWidget } from '@/modules/zendesk'
import { useAuthN, useLanguage } from '@/composables'
import { SELECTED_LOCALE } from '@/constants/localStorageKeys'

export default {
  name: 'App',
  components: { DashboardLayout, Toast, Modal },
  setup() {
    // cleaning organization devices and users on organization change
    const route = useRoute()
    const orgId = computed(() => route?.params?.id)
    const devices = useDevicesStore()
    const members = useMembersStore()
    const { updateLocale, saveLocaleInUserSettings } = useLanguage()
    const personalStore = usePersonalStore()
    const { profile, isProfileSet } = storeToRefs(personalStore)

    watch(orgId, () => {
      devices.$reset()
      members.$reset()
    })

    const authN = useAuthN()

    watch(authN.isAuthenticated, () => {
      if (authN.isAuthenticated.value) {
        chatWidget.authorize()
      } else {
        chatWidget.logout()
      }
    })

    const stopWheneverProfileSet = whenever(
      () => isProfileSet.value,
      () => {
        try {
          authN.isAuthenticated.value = true
          const localeStorageLang = localStorage.getItem(SELECTED_LOCALE)
          if (localeStorageLang) {
            updateLocale(localeStorageLang)
            saveLocaleInUserSettings(localeStorageLang)
            return
          }

          updateLocale(profile.value.ui_settings?.user_locale ?? fallbackLang.locale)
        } finally {
          nextTick(() => stopWheneverProfileSet())
        }
      },
      { immediate: true },
    )
  },
  computed: {
    layout() {
      /**
       * Current available layout
       * - DashboardLayout
       */
      return this.$route.meta.layout || 'Fragment'
    },
  },
  mounted() {
    chatWidget.setZIndex(2)
  },
}
</script>

<template>
  <RouterView v-slot="{ Component }">
    <component :is="layout">
      <component :is="Component" />
      <Modal />
      <Toast />
    </component>
  </RouterView>
</template>
