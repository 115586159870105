import { useI18n } from 'vue-i18n'
import en from './en'
import es from './es'
import fr from './fr'

export function useWebhooksLocale() {
  const i18n = useI18n({ messages: { en, es, fr } })
  return {
    ...i18n,
  }
}
