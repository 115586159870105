export default {
  'Enable webhook?': '¿Habilitar webhook?',
  'Enable': 'Habilitar',
  'enablingWebhookStartEvents':
    'Al habilitar este punto final de webhook, comenzará a recibir notificaciones sobre eventos.',

  'Disable webhook?': '¿Deshabilitar webhook?',
  'Disable': 'Deshabilitar',
  'disablingWebhookStopEvents':
    'Al deshabilitar temporalmente este punto final de webhook, dejará de recibir notificaciones sobre eventos.',

  'Remove webhook?': '¿Eliminar webhook?',
  'removingWebhookNoEventSent':
    'Eliminar este webhook significa que no se enviarán mensajes de webhook cada vez que ocurra este evento dentro de su organización.',
}
