<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { DialogBtn } from '@keyo/ui'
import WebhookUrlBox from '../components/WebhookUrlBox.vue'

import toast from '@/libs/toast'

import { useModal } from '@/composables'
import { useWebhooksStore } from '../pinia'
import { i18nUtils } from '@keyo/core/i18n'
import { useWebhooksLocale } from '../locales'

type Props = {
  webhookId: number
  webhookUrl: string
}

const props = defineProps<Props>()
const { t } = useWebhooksLocale()

const route = useRoute()
const modal = useModal()
const webhooks = useWebhooksStore()
const isSubmitting = ref(false)

async function submit() {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    await webhooks.enable(route.params.id, props.webhookId)
    modal.hide()
  } catch (e) {
    toast.show(i18nUtils.errorSomethingBroken, 'error')
    isSubmitting.value = false
  }
}
</script>

<template>
  <ModalCard
    tag="form"
    size="m"
    :heading="t('Enable webhook?')"
    :with-mobile-header-style="false"
    icon="question-circle"
    icon-color="grey"
    class="card"
    @submit.prevent="submit"
  >
    <WebhookUrlBox :url="webhookUrl" />
    <p class="text-body-m">
      {{ t('enablingWebhookStartEvents') }}
    </p>
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn type="submit" :loading="isSubmitting">
        {{ t('Enable') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
.card:deep(.dialog-icon) {
  color: var(--clr-icon-subtle);
}
p {
  margin: 0 1.25rem;
}
</style>
