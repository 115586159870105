import { useRoute } from 'vue-router'
import { useOrganizationsStore } from '../store/organizations'
import { useMembersStore } from '@/modules/members/pinia'
import { useDevicesStore } from '../store/devices'
import { computed, watch } from 'vue'
import type { Organization } from '@/modules/organization/types/model.ts'

// basic hierarchy power
const roles: Record<string, number> = {
  Owner: 2,
  Admin: 1,
  User: 0,
}

let isWatchingOrgChanges = false

export default function () {
  const route = useRoute()
  const members = useMembersStore()
  const organizations = useOrganizationsStore()
  const devices = useDevicesStore()

  const member = computed(() => members.items.get(route.params.memberId))
  const organization = computed<Organization>(() => organizations.items.get(route.params.id))
  const device = computed(() => devices.items.get(route.params.deviceId))

  if (!isWatchingOrgChanges) {
    isWatchingOrgChanges = true
    watch(
      () => route.params.id,
      id => id && organizations.fetchById(id),
    )
  }

  const isAuthnUserOwner = computed(() => {
    if (!organization.value) return false
    return Number(organization.value.owner.member_id) === Number(organization.value.member.id)
  })

  /**
   * Checks permission by role hierarchy
   * @param {Object} [m] - organization member. default to current route member
   * @returns {Boolean}
   */
  const canEditMember = (m = member.value) => {
    const loggedInMember = organization.value?.member
    if (!m || !loggedInMember?.role || !roles[loggedInMember.role]) return false
    return roles[loggedInMember.role] >= roles[m.role] && loggedInMember.id !== m.id
  }

  return { organization, member, device, canEditMember, isAuthnUserOwner }
}
