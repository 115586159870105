<script setup lang="ts">
import ModalCard from '@/components/modals/components/ModalCard.vue'
import type { IconType } from '@keyo/ui'
import { IconBox, Icon } from '@keyo/ui'
import { computed, onBeforeMount, ref, shallowRef } from 'vue'
import type { MappedRequirement, Organization } from '@/modules/organization/types/model.ts'
import { useModal } from '@/composables'
import RequirementBase from '@/modules/organization/modals/OrganizationInvitationRequirements/RequirementBase.vue'
import { getOrganizationData, getOrganizationRequirements } from '@/modules/organization/utils'
import { usePersonalStore } from '@/modules/account'
import LegalBanner from '@/modules/organization/components/LegalBanner.vue'
import OrganizationDataHeader from '@/modules/organization/components/OrganizationDataHeader.vue'
import type { Invitation } from '@/modules/account/pinia'

const props = defineProps<{
  invitation: Invitation
}>()

const isReady = ref(false)
const organizationData = ref<Organization>(props.invitation.organization as Organization)
const modal = useModal()
const personal = usePersonalStore()

const currentRequirementIndex = ref<number>(-1)
const currentRequirement = shallowRef<MappedRequirement | null>(null)
const requirements = ref<MappedRequirement[]>([])

const headerIcon = computed<IconType>(() => {
  if (currentRequirementIndex.value === -1) {
    return 'close-remove'
  }

  return 'left-3'
})

const handleNextRequirement = async () => {
  // mark current requirement as completed
  if (currentRequirement.value) {
    currentRequirement.value.isPending = false
  }

  // if all requirements are completed, close modal and refresh invites list
  if (currentRequirementIndex.value === requirements.value.length - 1) {
    // refresh invites
    const invitation = personal.invitations.find(inv => inv.id === props.invitation.id)

    if (invitation) {
      invitation.hasPendingRequirements = false
    }

    personal.addJoinedBanner(organizationData.value.id, organizationData.value.name)
    personal.getInvitations()
    modal.close()
    return
  }

  currentRequirementIndex.value += 1
  const requirement: MappedRequirement = requirements.value[currentRequirementIndex.value]

  if (requirement?.isPending) {
    currentRequirement.value = requirement
  } else {
    handleNextRequirement()
  }
}

const handlePrevRequirement = () => {
  currentRequirementIndex.value -= 1

  if (currentRequirementIndex.value < -1) {
    modal.close()
    return
  }

  const requirement: MappedRequirement = requirements.value[currentRequirementIndex.value]

  if (requirement?.isPending || !requirement) {
    currentRequirement.value = requirement
  } else {
    handlePrevRequirement()
  }
}

onBeforeMount(async () => {
  requirements.value = await getOrganizationRequirements(organizationData.value)
  organizationData.value = await getOrganizationData(organizationData.value.id)
  isReady.value = true
})
</script>

<template>
  <ModalCard class="organization-requirements" :with-mobile-header-style="false">
    <button class="header-icon" @click="handlePrevRequirement">
      <Icon :name="headerIcon" />
    </button>

    <OrganizationDataHeader
      v-if="organizationData"
      :organization="organizationData"
      class="org-header"
    />

    <main>
      <RequirementBase v-if="!currentRequirement" :loading="!isReady" @next="handleNextRequirement">
        <template #title>
          {{ $t('modules.organization.modals.organizationRequirements.title') }}
        </template>

        <template #description>
          {{
            $t(
              'modules.organization.modals.organizationRequirements.toJoinOrganizationCompleteRequirements',
            )
          }}
        </template>

        <ul class="org-requirement-list">
          <li v-if="!requirements.length" class="skeleton org-requirement-item" />
          <li
            v-for="requirement in requirements"
            :key="requirement.value"
            class="org-requirement-item"
          >
            <IconBox :color="requirement.isPending ? 'grey' : 'green'" :name="requirement.icon" />
            <p>{{ requirement.name }}</p>
          </li>
        </ul>

        <LegalBanner
          class="org-legal"
          :class="{
            skeleton: !organizationData,
          }"
          :terms-of-use="organizationData?.terms_of_use"
          :privacy-policy="organizationData?.privacy_policy"
        />
      </RequirementBase>

      <component
        :is="currentRequirement.component"
        v-else-if="currentRequirement?.component"
        :organization="organizationData"
        @next="handleNextRequirement"
      />
    </main>
  </ModalCard>
</template>

<style scoped lang="scss">
.organization-requirements {
  position: relative;
  .header-icon {
    display: flex;
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    color: var(--color-primary-white);
  }

  &.dialog-card.modal {
    display: flex;
    padding: 0;
  }

  .org-header {
    padding-top: 7.5rem;
  }

  :deep(.dialog-content) {
    margin-bottom: 0;
    grid-template-rows: max-content 1fr;
    flex: 1;
    gap: 0;
    height: 100%;
    overflow: auto;
    @include custom-scrollbar;
  }

  main {
    display: grid;
    background: #fff;
    width: 100%;
    height: 100%;

    .org-requirement-list {
      display: grid;
      gap: 0.5rem;
      flex: 1;

      .org-requirement-item {
        p {
          font: var(--text-label);
        }

        display: flex;
        align-items: center;
        gap: 1rem;
        padding-block: 1rem;
        position: relative;

        &:after {
          background-color: var(--color-grey-100);
          bottom: 0;
          content: '';
          height: 1px;
          position: absolute;
          width: 85%;
          inset-inline: 0;
          margin: auto;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }

        p {
          font: var(--text-body-s);
        }
      }
    }
  }

  .org-legal {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  :deep(.btn-container) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1.5rem 1.5rem;

    .btn {
      flex: auto;
    }
  }
}

@media screen and (min-width: $mobile) {
  .organization-requirements {
    main {
      overflow-y: auto;
      overflow-x: hidden;
      @include custom-scrollbar;
    }
  }
}
</style>
