<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

interface Props {
  tag?: keyof HTMLElementTagNameMap
  loading?: boolean
}

withDefaults(defineProps<Props>(), {
  tag: 'div',
})

defineEmits(['next'])

const { t } = useI18n()
const requirementContent = ref<HTMLElement>()

defineExpose({
  scrollToTop() {
    requirementContent.value?.scrollTo({ top: 0, behavior: 'smooth' })
  },
})
</script>

<template>
  <component :is="tag" class="requirement-base">
    <div ref="requirementContent" class="content">
      <h2 v-if="$slots.title" class="title">
        <slot name="title" />
      </h2>
      <p v-if="$slots.description" class="description">
        <slot name="description" />
      </p>

      <slot />
    </div>

    <div class="footer">
      <div class="divider" />
      <DialogBtn
        :type="tag === 'form' ? 'submit' : 'button'"
        :disabled="loading"
        :loading
        @click="$emit('next')"
      >
        {{ t('common.next') }}
      </DialogBtn>
    </div>
  </component>
</template>

<style lang="scss" scoped>
.requirement-base {
  padding: 2rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    display: grid;
    padding-inline: 2rem;

    .title,
    .description {
      text-align: left;
    }

    :deep(.dropdown) {
      --dropdown-menu-height: 15rem;
    }

    .title,
    .description {
      margin-bottom: 1rem;
    }

    .title {
      font: var(--text-heading-s);
    }

    .description {
      font: var(--text-body-m);
    }
  }

  .footer {
    display: grid;
    gap: 2rem;
    padding-inline: 2rem;

    .divider {
      height: 1px;
      background: var(--color-grey-200);
      display: none;
    }

    :deep(.btn) {
      margin-top: 1rem;
    }
  }
}

@media screen and (min-width: $mobile) {
  .requirement-base {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include custom-scrollbar;
    .content {
      height: 100%;

      // scrollbar styles
      overflow-y: auto;
      overflow-x: hidden;
      @include custom-scrollbar;

      .title,
      .description {
        text-align: center;
      }

      :deep(.dropdown) {
        --dropdown-menu-height: 11rem;
      }
    }
    .footer {
      .divider {
        display: block;
      }

      :deep(.btn) {
        margin-top: 0;
      }
    }
  }
}
</style>
