import type { RouteRecordRaw } from 'vue-router'
import { FEATURE_FLAGS } from '@/constants/config.ts'

export const PersonalRedirectActions = {
  CONFIRM_ENROLL: 'confirm-enroll',
}

const routes: RouteRecordRaw[] = [
  {
    path: '/personal',
    name: 'personal',
    redirect: { name: 'personal.overview' },
    meta: {
      layout: 'DashboardLayout',
    },
    children: [
      {
        path: 'overview',
        name: 'personal.overview',
        component: () =>
          FEATURE_FLAGS.ENABLE_NEW_DASHBOARD.value
            ? import('@/modules/account/pages/Home.vue')
            : import('@/modules/account/pages/HomeOld.vue'),
      },
      {
        path: 'invites/:id?',
        name: 'personal.invites',
        component: () => import('@/modules/personal/pages/Invitations.vue'),
        meta: {
          layout: 'DashboardLayout',
          title: 'Invitations',
          breadcrumbs: [{ label: 'Invitations', route: 'personal.invites' }],
        },
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            name: 'personal.profile',
            component: () => import('@/modules/account/pages/Profile.vue'),
            redirect: { name: 'personal.profile.details' },
            meta: {
              title: 'common.profile',
              breadcrumbs: [{ label: 'common.profile', route: 'personal.profile' }],
            },
            children: [
              {
                path: 'details',
                name: 'personal.profile.details',
                component: () => import('@/modules/account/pages/ProfileDetails.vue'),
                meta: {
                  tab: 'details',
                },
              },
              {
                path: 'address',
                meta: {
                  tab: 'address',
                },
                children: [
                  {
                    path: '',
                    name: 'personal.profile.address',
                    component: () => import('@/modules/account/pages/ProfileAddressBook.vue'),
                  },
                  {
                    path: 'add',
                    name: 'personal.profile.address.add',
                    component: () => import('@/modules/account/pages/ProfileAddressBook.vue'),
                    meta: {
                      mode: 'add',
                    },
                  },
                  {
                    path: ':addressId',
                    name: 'personal.profile.address.edit',
                    component: () => import('@/modules/account/pages/ProfileAddressBook.vue'),
                    meta: {
                      mode: 'edit',
                    },
                  },
                ],
              },
            ],
          },

          {
            path: 'edit',
            name: 'personal.profile.edit',
            component: () => import('@/modules/account/pages/ProfileEdit.vue'),
            meta: {
              title: 'modules.personal.pages.editProfile',
              hideMobileToggleButton: true,
              breadcrumbs: [
                { label: 'common.profile', route: 'personal.profile' },
                { label: 'Edit', route: 'personal.profile.edit' },
              ],
            },
          },
        ],
      },
      {
        path: 'biometrics',
        name: 'personal.biometrics',
        component: () => import('@/modules/account/pages/Biometrics.vue'),
        meta: {
          title: 'layouts.biometrics',
          breadcrumbs: [{ label: 'layouts.biometrics', route: 'personal.biometrics' }],
        },
      },
      {
        path: 'biometric/confirm-enroll',
        name: 'personal.biometric.confirm-enroll',
        redirect: to => {
          return {
            name: 'personal.overview',
            query: {
              redirectAction: PersonalRedirectActions.CONFIRM_ENROLL,
              ...to.query,
            },
          }
        },
      },
      {
        path: 'security',
        children: [
          {
            path: '',
            name: 'personal.security',
            component: () => import('@/modules/account/pages/Security.vue'),
            meta: {
              title: 'common.loginAndSecurity',
              breadcrumbs: [{ label: 'common.loginAndSecurity', route: 'personal.security' }],
            },
          },
          {
            path: 'mfa',
            name: 'personal.security.mfa',
            component: () => import('@/modules/account/pages/SecurityMfa.vue'),
            meta: {
              title: 'common.twoFactorAuthentication',
              hideMobileToggleButton: true,
              breadcrumbs: [
                { label: 'common.loginAndSecurity', route: 'personal.security' },
                { label: 'common.twoFactorAuthentication', route: 'personal.security.mfa' },
              ],
            },
          },
        ],
      },
    ],
  },
]

if (import.meta.env.APP_ENABLE_WALLET) {
  routes[0].children?.push({
    path: 'wallet',
    name: 'personal.wallet',
    component: () => import('@/modules/wallet/pages/Wallet.vue'),
    meta: {
      title: 'layouts.wallet',
      breadcrumbs: [{ label: 'layouts.wallet', route: 'personal.wallet' }],
    },
  })
}

export default routes
