export default {
  'Enable webhook?': 'Activer le webhook?',
  'Enable': 'Activer',
  'enablingWebhookStartEvents':
    'En activant ce point de terminaison webhook, il commencera à recevoir des notifications sur les événements.',

  'Disable webhook?': 'Désactiver le webhook ?',
  'Disable': 'Désactiver',
  'disablingWebhookStopEvents':
    "En désactivant temporairement ce point de terminaison du webhook, il cessera de recevoir des notifications d'événements.",

  'Remove webhook?': 'Supprimer le webhook ?',
  'removingWebhookNoEventSent':
    "La suppression de ce webhook signifie qu'aucun message webhook ne sera envoyé lorsque cet événement se produit au sein de votre organisation.",
}
