import { apiV3, contentTypeV2 } from '@/api'

type ID = string | number
type Body = {
  ownerId: ID
  code: string[] | number[]
  client_id: string
}

export function ownerTransfer(orgId: ID, { ownerId, code, client_id }: Body) {
  return apiV3.post(
    `/organizations/${orgId}/members/transfer-ownership/`,
    {
      new_owner_id: String(ownerId),
      client_id,
      code: code.join(''),
    },
    {
      headers: contentTypeV2,
    },
  )
}
